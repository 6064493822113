import { DateTime } from "luxon";

const useDateFormatter = (timezone = 'system') => {
	const formatTime = (date: string, zone:string = timezone) => DateTime.fromISO(date, {zone} ).toLocaleString(DateTime.TIME_WITH_SECONDS);
	const formatDateTime = (date: string, zone:string = timezone) => DateTime.fromISO(date, {zone} ).toLocaleString(DateTime.DATETIME_SHORT);
	const formatDate = (date: string, zone:string = timezone) => DateTime.fromISO(date, {zone} ).toLocaleString(DateTime.DATE_SHORT);

	return {
		formatTime,
		formatDateTime,
		formatDate,
	};
}

export default useDateFormatter;