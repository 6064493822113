import { useEffect, useState } from "react";
import { EyeTrackingJoyStick, Position } from "./EyeTrackingJoyStick";

interface EyeTrackingComponentProps {
	eyeSide: "left" | "right";
	savedCoordinates: any;
}

const EyeTrackingComponent = ({
	eyeSide,
	savedCoordinates,
}: EyeTrackingComponentProps) => {
	const [coordinates, setCoordinates] = useState<Position[]>([]);

	useEffect(() => {
		if (savedCoordinates) {
			let parsedCoords = JSON.parse(savedCoordinates);
			setCoordinates(parsedCoords);
		}
	}, [savedCoordinates]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div
				style={{
					paddingRight: "8px",
					paddingBottom: "12px",
					display: "flex",
					alignItems: "baseline",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "baseline",
						borderBottom: "1px solid #333",
					}}
				>
					<span style={{ fontWeight: "bold", width: "150px" }}>
						{eyeSide === "left" ? "Left Eye" : "Right Eye"}
					</span>
				</div>
			</div>
			<div style={{ flex: 1 }}>
				<EyeTrackingJoyStick coordinates={coordinates} />
			</div>
		</div>
	);
};

export default EyeTrackingComponent;