import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const fingerToNoseTestAPI = {
	getFingerToNoseTest: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/finger-to-nose-test`)
			.then((response) => response.data as FingerToNoseTestDTO[]);
	},
	getFingerToNoseTestItem: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/finger-to-nose-test-items`)
			.then((response) => response.data as FingerToNoseTestItemsDTO[]);
	},
	getFingerToNoseTestTimeline: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/finger-to-nose-test-timeline`)
			.then((response) => response.data as FingerToNoseTestTimelineDTO[]);
	},
};

export default fingerToNoseTestAPI;
