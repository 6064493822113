import React, { useEffect, useState } from 'react';
import Page from 'components/common/Page';
import Title from 'components/common/Title';

import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import T from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import userAPI from './Users/userAPI';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const DemoUsage = () => {
	
	const [isLoading, setIsLoading] = useState(true);
	const initialFilter = {startDate: null, endDate:null}
	const [filter, setFilter] = useState(initialFilter);
	
	const [usageReport, setUsageReport] = useState<DemoUsageReport[]>([]);
	useEffect(() => {
		const fetchData = async () => {
			const usageReport = await userAPI.getDemoUsage();
			setUsageReport(usageReport);
		};
	
		fetchData();
	}, []);

	const userCount = usageReport.length;

	const columns: GridColDef[] = [
		{
			field: 'email',
			headerName: 'Email',
			flex: 3
		},
		{
			field: 'count',
			headerName: 'Assessments',
			flex: 1
		},
		{
			field: 'finalizedCount',
			headerName: 'Finalized Count',
			flex: 1
		}
	];
	
	return (
		<Page>
			<Title>Demo Usage</Title>
			{/* <Grid item md={3}>
				<T variant='h2'>Filters</T>
				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<DatePicker
						label="Start"
						value={filter.startDate}
						onChange={(newValue) => { setFilter({...filter, startDate: newValue! }); }}
						renderInput={(params) => <TextField {...params} margin='normal' />}
					/>
					<DatePicker
						label="End"
						value={filter.endDate}
						onChange={(newValue) => { setFilter({...filter, endDate: newValue! }); }}
						renderInput={(params) => <TextField {...params} margin='normal' />}
					/>
				</LocalizationProvider>
			</Grid> */}
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} lg={4} >
					<Card variant='elevation' sx={{p:1, elevation:12}} >
						<CardContent>
							<T display='flex' alignItems='flex-end' fontSize='2rem'>
								<AssignmentIcon sx={{fontSize: '3rem'}} />
								User Count
							</T>
							<T textAlign='center' fontWeight='700' fontSize='4rem'>{userCount}</T>
						</CardContent>
					</Card>
				</Grid>
				<Grid item lg={12}>
					<DataGrid
						rows={usageReport}
						columns={columns}
						disableSelectionOnClick
						autoHeight
						getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
					/>
				</Grid>
			</Grid>
		</Page>
	);
}
export default DemoUsage;