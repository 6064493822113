import * as React from 'react';

import T from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
interface IWATDisplayDetail {
	id: number;
	action: string;
	degree?: number;
	step: number;
	occurrence?: number;
}

export interface IProps {
	title: string;
	stepsSet:WalkAndTurnTestItemDTO[];
	stepAdjustment: number;
}
export const WalkandTurnStepResults: React.FC<IProps> = (props: IProps) => {

	const sortWATResult = (a: IWATDisplayDetail, b: IWATDisplayDetail) => {
		return a.step! > b.step! ? 1 : -1;
	};
	const calculateActionTimes = (items: WalkAndTurnTestItemDTO[], targetAction: keyof WalkAndTurnTestItemDTO, stepAdjustment: number = 0) => {
		const result: IWATDisplayDetail[] = [];
		items.forEach(item => {
			if (typeof item[targetAction] === 'boolean' && item[targetAction] === true) {
				// const existingStep = result.find(re => re.step === item.step && re.occurrence !== undefined);
				// if (existingStep) {
				// 	existingStep.occurrence! += 1;
				// } else {
				result.push({
					id: item.id,
					action: targetAction,
					step: item.step !== undefined ? item.step - stepAdjustment : item.step,
					occurrence: 1,
				} as IWATDisplayDetail);
				// }
			} else if (typeof item[targetAction] === 'string' && (item[targetAction] === 'left' || item[targetAction] === 'right')) {
				result.push({
					id: item.id,
					action: targetAction,
					step: item.step !== undefined ? item.step - stepAdjustment : item.step,
					occurrence: 1,
				} as IWATDisplayDetail);
			} else if (typeof item[targetAction] === 'number' && item[targetAction] !== undefined) {
				result.push({
					id: item.id,
					action: targetAction,
					step: item.step,
					degree: item.carouselSection,
				} as IWATDisplayDetail);
			}
		});
		return result.sort(sortWATResult);
	};

	const getActionSteps = (targetArray: IWATDisplayDetail[]) => {
		if (!targetArray) {
			return '-';
		}
		else if (targetArray.length <= 0) {
			return <span className="text-gray-400">N/A</span>;
		}

		let steps = '';
		targetArray.forEach((item, index) => {
			if (index > 0) {
				steps += ', ';
			}
			steps += item.step;
		});

		return <span className="font-weight-bold">{steps}</span>;
	};

	const getTotalOccurrence = (targetArray: IWATDisplayDetail[]) => {
		return targetArray.reduce((a, b) => {
			return a + (b.occurrence ?? 0);
		}, 0);
	};

	const IsIncorrectSteps = (steps: WalkAndTurnTestItemDTO[]): boolean => {
		if(steps.length === 0) return true;
		
		const lastStep = steps[steps.length - 1];
		return lastStep.step! - props.stepAdjustment! !== 9;
	};

	const getTotalSteps = () => {
		if (props.stepAdjustment !== undefined && props.stepAdjustment > 0) {
			return props.stepsSet[props.stepsSet.length - 1].step! - props.stepAdjustment;
		}
		else {
			return props.stepsSet[props.stepsSet.length - 1].step!;
		}
	};

	const stepsSetStopped = calculateActionTimes(props.stepsSet, 'stopped', props.stepAdjustment);
	const stepsSetSteppedOff = calculateActionTimes(props.stepsSet, 'carouselSection', props.stepAdjustment);
	const stepsSetRaisedArms = calculateActionTimes(props.stepsSet, 'raisedArms', props.stepAdjustment);
	const stepsSetMissedHT = calculateActionTimes(props.stepsSet, 'missedHeelToToe', props.stepAdjustment);

	return (
		<>
			<T variant='h4'>{props.title}</T>
			<T variant='h6' component='h5'>Results:</T>
			<TableContainer>
				<Table>
					<TableHead sx={{ '& .MuiTableCell-head': {fontWeight: 700, borderBottom: 2, textTransform: 'uppercase', paddingBottom: 1} }}>
						<TableRow>
							<TableCell>Action</TableCell>
							<TableCell align='right'>Steps Observed At</TableCell>
							<TableCell sx={{textAlign:'right'}} >Count</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Stopped Walking</TableCell>
							<TableCell align='right'>{getActionSteps(stepsSetStopped)}</TableCell>
							<TableCell align='right' sx={[stepsSetStopped.length > 0 && {fontWeight:'bold'}]}>{getTotalOccurrence(stepsSetStopped)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Stepped Off Line</TableCell>
							<TableCell align='right'>{getActionSteps(stepsSetSteppedOff)}</TableCell>
							<TableCell align='right' sx={[stepsSetSteppedOff.length > 0 && {fontWeight:'bold'}]}>{stepsSetSteppedOff.length}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Missed Heel-to-Toe</TableCell>
							<TableCell align='right'>{getActionSteps(stepsSetMissedHT)}</TableCell>
							<TableCell align='right' sx={[stepsSetMissedHT.length > 0 && {fontWeight:'bold'}]}>{getTotalOccurrence(stepsSetMissedHT)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Arms for Balance</TableCell>
							<TableCell align='right'>{getActionSteps(stepsSetRaisedArms)}</TableCell>
							<TableCell align='right' sx={[stepsSetRaisedArms.length > 0 && {fontWeight:'bold'}]}>{getTotalOccurrence(stepsSetRaisedArms)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Total Steps</TableCell>
							<TableCell color='text.disabled'  align='right'>N/A</TableCell>
							<TableCell align='right' sx={[IsIncorrectSteps(props.stepsSet) && {fontWeight: 'bold'}]}>
								{
									props.stepsSet.length >= 1 ?
									getTotalSteps() :
									<T color='text.disabled'>N/A</T>
								}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
