import * as React from 'react';

import TextField from '@mui/material/TextField';
import T from '@mui/material/Typography';

import DateUpdated from './DateUpdated';
import useDateFormatter from 'common/useDateFormatter';

interface IProps {
	caseInfo: CaseDTO;
	onCaseTextChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	submittedCase: boolean;
	subjectName: string;
	isOpinionAdditionalNotesRequired?: boolean;
	order: number;
}

const CaseNarrativeOpinion = (props: IProps) => {
	const { caseInfo, submittedCase, subjectName, order } = props;

	const {formatDateTime} =  useDateFormatter(caseInfo.ianaTimeZone);

	let decision;

	if (caseInfo.custodyOther) {
		decision = 'take subject to custody due to other reasons';
	} else if (caseInfo.custodyPerformance) {
		decision = 'has performed poorly and was arrested';
	} else if (caseInfo.releaseOther) {
		decision = 'release subject due to other reasons';
	} else if (caseInfo.releasePerformance) {
		decision = 'has performed well and was released';
	}

	return (
		<>
			<T variant='h2'>{`${order}. Declaration`}</T>
			{decision ? (
				<>
					<T sx={{mb:3, pb:2}}>
						Based on the subject's results during the standardized field sobriety test,{' '}
						<strong>{subjectName}</strong>{' '}
						<strong>{decision}</strong>.
					</T>
					<T variant='h6' component='h5'>Explanation for Decision: </T>
					<T sx={{mb:3, pb:2}}>
						<strong>Declaration Notes: </strong>
						{caseInfo.decisionExplanation || <T fontStyle='italic'>No declaration notes added.</T>}
					</T>
					<T sx={{mb:3, pb:2}}>
						<strong>Additional Notes: </strong>
						{caseInfo.caseNarrative || <T fontStyle='italic'>No additional notes added.</T>}
					</T>
				</>
			) : (
				<T fontStyle='italic'>No judgement recorded.</T>
			)}

			{props.isOpinionAdditionalNotesRequired && (
				<T color='error.main' sx={{mb:3, pb:2}}>
					This field is required to sign and complete
				</T>
			)}
			{submittedCase ?
				(
					<TextField
						multiline
						rows={9}
						fullWidth
						name="opinionAdditionalNotes"
						label="Post-Test Comments: "
						value={caseInfo.opinionAdditionalNotes}
						onChange={(evt: any) => props.onCaseTextChange(evt)}
					/>
				) :
				(
					<T>
						<strong>Post-Test Comments: </strong>
						{caseInfo.opinionAdditionalNotes ? caseInfo.opinionAdditionalNotes : <T fontStyle='italic'>No post-test comments added.</T>}
						<DateUpdated dateUpdated={caseInfo.dateUpdated!} formatter={formatDateTime} />
					</T>
				)
			}
		</>
	);
};

export default CaseNarrativeOpinion;