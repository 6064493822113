import React, { useEffect, useState } from 'react';

import {useDropzone} from 'react-dropzone';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import { Alert, Box, Button, Card, CardActions, CardContent, Divider, Grid, Snackbar, TextField, styled } from '@mui/material';
import settingsApi from './settingsApi';
import { Camera } from '@mui/icons-material';

const SGrid = styled(Grid)(({theme}) => ({
	padding: theme.spacing(1),
	textAlign: 'center',
}));
const initialSettings: TenantSettings = {
	feedbackEmail: '',
	portalUrl: '',
	defaultIANATimeZone: '',
	reportPdfStorageContainer: '',
	reportPdfSyncContainer: '',
	publicStorageContainer: '',
	logoUrl: '',
	saveUsersInB2C: true,
};

const SettingsPage = () => {
	const [logoFile, setLogoFile] = useState<File>();
	const [logoPreview, setLogoPreview] = useState<string | null>(null);
	const [settings, setSettings] = useState<TenantSettings>(initialSettings);
	
	const [successIsOpen, setSuccessIsOpen] = useState(false);
	const [errorIsOpen, setErrorIsOpen] = useState(false);
	
	const {getRootProps, getInputProps, isDragAccept} = useDropzone({multiple: false, onDropAccepted(files) {
		const file = files[0];
		setLogoFile(file);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			setLogoPreview(reader.result as string);
		};
		
	},});

	useEffect(() => {
		const fetchSettings = async () => {
			const data = await settingsApi.getTenantSettings();
			setSettings(data);
			if(data.logoUrl){
				setLogoPreview(data.logoUrl);
			}
		};
	
		fetchSettings();
	}, []);
	
	const handleTextChanges = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		console.log(e.target.name, e.target.value);
		setSettings(u => ({...u, [e.target.name]: e.target.value}));
	}

	const onSave = async () => {
		await settingsApi.saveTenantSettings(settings, logoFile);
		setSuccessIsOpen(true);
	}

	const styles = {
		dropzoneContainer: {
			position: 'relative',
			height: '100%',
		},
		dropzoneOverlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			backgroundColor: 'primary.main',
			opacity: isDragAccept ? '0.7' : '0',
			transition: 'opacity 0.3s ease-in-out',
			'&:hover': {
				opacity: '0.7',
			},
		},
		dropzoneOverlayContent: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			color: 'white',
			textAlign: 'center',
		},
	};
	
	return (
		<Page>
			<Title>Settings</Title>
			<Grid container spacing={1}>
				<SGrid item sm={4}>
					<Card {...getRootProps({ sx: styles.dropzoneContainer })}>
						<input {...getInputProps()} />
						{logoPreview
							? <img src={logoPreview} alt="Selected logo" width='100%' />
							: "Click or drag a logo here"
						}
						<Box sx={styles.dropzoneOverlay} bgcolor={'darkred'}>
							<Box sx={styles.dropzoneOverlayContent}>
							<Camera /><br />
							Update Logo
							</Box>
						</Box>
					</Card>
					
				</SGrid>
				<SGrid item sm={8}>
					<Card>
						<CardContent>
							<Grid container spacing={1}>
								<Grid item xs={12} sm={6} lg={3}>
									<TextField
										label="Feedback email"
										name="feedbackEmail"
										value={settings.feedbackEmail}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={3}>
									<TextField
										label="Portal Url"
										name="portalUrl"
										value={settings.portalUrl}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={3}>
									<TextField
										label="IANA Timezone"
										name="defaultIANATimeZone"
										value={settings.defaultIANATimeZone}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={3}>
									<TextField
										label="Report Storage Container"
										name="reportPdfStorageContainer"
										value={settings.reportPdfStorageContainer}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={3}>
									<TextField
										label="Report Sync Storage Container"
										name="reportPdfSyncContainer"
										value={settings.reportPdfSyncContainer}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6} lg={3}>
								<TextField
										label="Public Storage Container"
										name="publicStorageContainer"
										value={settings.publicStorageContainer}
										onChange={handleTextChanges}
										fullWidth
										required
									/>
								</Grid>
							</Grid>
						</CardContent>
						<Divider />
						<CardActions sx={{justifyContent: 'flex-end'}}>
							<Button variant='contained' onClick={onSave}>Save</Button>
						</CardActions>
					</Card>
				</SGrid>
			</Grid>
			
            <Snackbar open={successIsOpen} autoHideDuration={6000} onClose={() => setSuccessIsOpen(false)}>
                <Alert severity="success" onClose={() => setSuccessIsOpen(false)}>The settings have been saved</Alert>
            </Snackbar>
            <Snackbar open={errorIsOpen} autoHideDuration={6000} onClose={() => setErrorIsOpen(false)}>
                <Alert severity="error" onClose={() => setErrorIsOpen(false)}>There was an error saving!</Alert>
            </Snackbar>
		</Page>
	);
}
export default SettingsPage;