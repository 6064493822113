import React from 'react';
import { Grid, TextField } from '@mui/material';
import T from '@mui/material/Typography';
import useDateFormatter from 'common/useDateFormatter';

interface IProps {
	caseDetails?: CaseDTO;
	subjectInformation?: SubjectInformationDTO;
	isEditable: boolean;
	onNotesChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

const CaseDetailInformation = (props: IProps) => {
	const {caseDetails, subjectInformation} = props;
	const {formatDate, formatDateTime} = useDateFormatter(caseDetails?.ianaTimeZone!);
	return (
		<>
			<T variant='h2'>Case Information</T>
			{subjectInformation && caseDetails && <>
				<Grid container sx={{mb: 3, pb: 2}}>
					<Grid item xs={3}>
						<T fontWeight="bold">First Name</T>
						{subjectInformation.firstName || <T fontStyle="italic">No name provided.</T>}
					</Grid>
					<Grid item xs={3}>
						<T fontWeight="bold">Last Name</T>
						{subjectInformation.lastName || <T fontStyle="italic">No name provided.</T>}
					</Grid>
					<Grid item xs={3}>
						<T fontWeight="bold">Subject DOB</T>
						{formatDate(caseDetails.subjectDOB!)}
					</Grid>
					<Grid item xs={3}>
						<T fontWeight="bold">Lead investigator</T>
						{caseDetails.leadInvestigator}
					</Grid>
				</Grid>
				{caseDetails?.abortNotes &&
				<Grid container sx={{mb:3, pb:2}}>
					<Grid item xs={12}>
						<T fontWeight="bold">This case was manually aborted prior to completion.</T>
					</Grid>
					<Grid item xs={12}>
						<T  fontWeight="bold">Aborted Notes:</T>
					</Grid>
					<Grid item xs={12}>
						{caseDetails?.abortNotes}
					</Grid>
				</Grid>
				}
				<Grid container sx={{mb:3, pb:2}}>
					{props.isEditable === true &&
					<Grid item xs={12}>
						<TextField
							multiline
							rows={9}
							fullWidth
							name="caseInformationAdditionalNotes"
							label="Case Information Comments:"
							value={caseDetails.caseInformationAdditionalNotes}
							onChange={props.onNotesChanged}
						/>
						{caseDetails.caseInformationAdditionalNotes}
					</Grid>
					}
					{props.isEditable === false &&
					<>
					<Grid item xs={12} fontWeight="bold">Case Information Comments:</Grid>
					<Grid item xs={12}>
						{caseDetails.caseInformationAdditionalNotes ? caseDetails.caseInformationAdditionalNotes : <T fontStyle="italic">No case information comments added.</T>}
						<T variant='caption' fontStyle="italic">{formatDateTime(caseDetails.dateUpdated!)}</T>
					</Grid>
					</>
					}
				</Grid>
			</>}
		</>
	);
}
export default CaseDetailInformation;