import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const authAPI = {
	getSettings: () => {
		return axios.get(`${apiUrl}/anon/web/settings`)
			.then(response => response.data as WebSettings);
	},
	getTenantSettings: () => {
		return axios.get(`${apiUrl}/tenant/settings`)
			.then(response => response.data as TenantSettings);
	},
	saveTenantSettings: (settings: TenantSettings, logo?: File) => {
		let formData = new FormData();
		if(logo) 
			formData.append('file', logo!);
		
		for (const key in settings) {
			if (settings.hasOwnProperty(key)) {
				const value = settings[key as keyof TenantSettings];
				formData.append(key, value as any);
			}
		}

		return axios.post(
			`${apiUrl}/tenant/settings`,
			formData
		);
	}
};

export default authAPI;