import fingerToNoseTestAPI from "services/fingerToNoseTestAPI";
import lackOfConvergenceTestAPI from "services/lackOfConvergenceTestAPI";
import modifiedRombergTestAPI from "services/modifiedRombergTestAPI";
import pupilSizeTestAPI from "services/pupilSizeTestAPI";

type SetStateFunction<T> = (value: T) => void;

export const fetchAndSetArideData = async (
	caseId: string,
	setPupilSizeTests: SetStateFunction<PupilSizeTestDTO[]>,
	setLocTests: SetStateFunction<LackOfConvergenceTestDTO[]>,
	setFtnTests: SetStateFunction<FingerToNoseTestDTO[]>,
	setFtnTestItems: SetStateFunction<FingerToNoseTestItemsDTO[]>,
	setFtnTestTimeline: SetStateFunction<FingerToNoseTestTimelineDTO[]>,
	setMrbTests: SetStateFunction<ModifiedRombergTestDTO[]>,
	setMrbTestItems: SetStateFunction<ModifiedRombergTestItemDTO[]>,
) => {
	const pupilSizeTestProm = pupilSizeTestAPI.getPupilSizeTest(caseId);
	const locTestProm = lackOfConvergenceTestAPI.getLackOfConvergenceTest(caseId);
	const ftnTestProm = fingerToNoseTestAPI.getFingerToNoseTest(caseId);
	const ftnTestItemsProm = fingerToNoseTestAPI.getFingerToNoseTestItem(caseId);
	const ftnTestTimelineProm = fingerToNoseTestAPI.getFingerToNoseTestTimeline(caseId);
	const mrbTestProm = modifiedRombergTestAPI.getModifiedRombergTest(caseId);
	const mrbTestItemsProm = modifiedRombergTestAPI.getModifiedRombergTestItem(caseId);

	const pupilSizeTests = await pupilSizeTestProm;
	setPupilSizeTests(pupilSizeTests);

	const locTests = await locTestProm;
	setLocTests(locTests);

	const ftnTests = await ftnTestProm;
	setFtnTests(ftnTests);

	const ftnTestItems = await ftnTestItemsProm;
	setFtnTestItems(ftnTestItems);

	const ftnTestTimeline = await ftnTestTimelineProm;
	setFtnTestTimeline(ftnTestTimeline);
	
	const mrbTests = await mrbTestProm;
	setMrbTests(mrbTests);

	const mrbTestItems = await mrbTestItemsProm;
	setMrbTestItems(mrbTestItems);
};
