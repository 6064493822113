import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import CaseDetailInformation from "./CaseDetailInformation";
import CaseNarrativeDrivingAndStop from './CaseNarrativeDrivingAndStop';
import CaseSFSTSetup from './CaseSFSTSetup';
import CaseInitialInterview from './CaseInitialInterview';
import CaseDetailHGN from './CaseDetailHGN';
import CaseDetailWAT from './CaseDetailWAT';
import CaseDetailOLS from './CaseDetailOLS';
import CaseNarrativeOpinion from './CaseNarrativeOpinion';

import caseAPI from "./caseAPI";
import caseObservationsAPI from './caseObservationsAPI';
import yesNoRecordAPI from './yesNoRecordAPI';
import sfstSetupAPI from './sfstSetupApi';
import horizontalGazeTestAPI from './horizontalGazeTestAPI';
import walkAndTurnTestAPI from './walkAndTurnTestAPI';
import oneLegStandTestAPI from './oneLegStandTestAPI';
import protocolModuleAPI from 'services/protocolModuleAPI';

import { Alert, Box, Button, CircularProgress, Snackbar, Tab, Tabs } from '@mui/material';
import T from '@mui/material/Typography';
import PickListContext from 'pickList/pickListContext';
import AuthContext from 'auth/AuthContext';
import CaseDetailPupilSize from './modules/PupilSize/CaseDetailPupilSize';
import CaseDetailLOC from './modules/LOC/CaseDetailLOC';
import CaseDetailFTN from './modules/FTN/CaseDetailFTN';
import useArideData from 'hooks/useArideData';
import CaseDetailMRB from './modules/MRB/CaseDetailMRB';
import { ProtocolModuleTitle } from 'types/ProtocolModules';

// historical info: migrated from CaseDetailNarrative.tsx component
const CaseDetailPage = () => {
	const { caseId } = useParams<{ caseId: string }>();
	const [currentTab, setCurrentTab] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isSaveToastOpen, setIsSaveToastOpen] = useState(false);


	const { pickListItems } = useContext(PickListContext);
	const { tenantSettings } = useContext(AuthContext);
	const [allProtocols, setAllProtocols] = useState<ProtocolModuleDTO[]>([]);
	const [protocolModules, setProtocolModules] = useState<ProtocolModuleDTO[]>([]);

	const [caseDetails, setCaseDetails] = useState<CaseDTO>({});
	const [caseObservations, setCaseObservations] = useState<CaseObservationsDTO>({});
	const [subjectInformation, setSubjectInformation] = useState<SubjectInformationDTO>({});
	const [yesNoRecords, setYesNoRecords] = useState<YesNoRecordDTO[]>([{}]);
	const [sfstSetup, setSfstSetup] = useState<SFSTSetupDTO>({});
	const [hgnTests, setHgnTests] = useState<HorizontalGazeTestDTO[]>([{}]);
	const [watTests, setWatTests] = useState<WalkAndTurnTestDTO[]>([{}]);
	const [watTestItems, setWatTestItems] = useState<WalkAndTurnTestItemDTO[]>([{}]);
	const [olsTests, setOlsTests] = useState<OneLegStandTestDTO[]>([{}]);
	const [olsTestErrors, setOlsTestErrors] = useState<OneLegStandTestErrorDTO[]>([{}]);


	useEffect(() => {
		const fetchCase = async () => {
			setIsLoading(true);
			const caseDataProm = caseAPI.getCase(caseId);
			const caseObservationProm = caseObservationsAPI.getCaseObservation(caseId);
			const subjectInformationProm = caseObservationsAPI.getSubjectInformation(caseId);
			const yesNoRecordProm = yesNoRecordAPI.getYesNoRecordItems(caseId);
			const sfstSetupProm = sfstSetupAPI.getSFSTSetup(caseId);
			const hgnTestProm = horizontalGazeTestAPI.getHorizontalGazeTest(caseId);
			const watTestProm = walkAndTurnTestAPI.getWalkAndTurnTest(caseId);
			const watTestItemsProm = walkAndTurnTestAPI.getWalkAndTurnTestItems(caseId);
			const olsTestProm = oneLegStandTestAPI.getOneLegStandTest(caseId);
			const olsTestErrorsProm = oneLegStandTestAPI.getOneLegStandTestErrors(caseId);

			const caseData = await caseDataProm;
			setCaseDetails(caseData);
			const caseObservations = await caseObservationProm;
			setCaseObservations(caseObservations);
			const subjectInformation = await subjectInformationProm;
			setSubjectInformation(subjectInformation);
			const yesNoRecords = await yesNoRecordProm;
			setYesNoRecords(yesNoRecords);
			const sfstSetup = await sfstSetupProm;
			setSfstSetup(sfstSetup);

			//todo: may need to sort these
			const hgnTests = await hgnTestProm;
			setHgnTests(hgnTests);
			const watTests = await watTestProm;
			setWatTests(watTests);
			const watTestItems = await watTestItemsProm;
			setWatTestItems(watTestItems);
			const olsTests = await olsTestProm;
			setOlsTests(olsTests);
			const olsTestErrors = await olsTestErrorsProm;
			setOlsTestErrors(olsTestErrors);
			setIsLoading(false);
		};
		fetchCase();
	}, [caseId]);

	const { protocolId } = caseDetails;
	const {
		pupilSizeTests,
		locTests,
		ftnTests,
		ftnTestItems,
		ftnTestTimeline,
		mrbTests,
		mrbTestItems,
	} = useArideData({ caseId, protocolId })

	useEffect(() => {
		const fetchProtocols = async () => {
			const protocols = await protocolModuleAPI.getProtocolModules();
			const data: ProtocolModuleDTO[] = protocols.protocolModules;
			setAllProtocols(data);

			if (protocolId) {
				const filteredProtocols = data.filter((item) => {
					return item.protocolId === protocolId;
				})
				const sortedProtocols = filteredProtocols.sort((a, b) => a.order! - b.order!);
				setProtocolModules(sortedProtocols)
			}
		}
		fetchProtocols();
	}, [protocolId])

	const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
		console.log('newtab', newValue);
		setCurrentTab(newValue);
	}

	const onCaseDetailsNotesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCaseDetails({ ...caseDetails, [e.target.name]: e.target.value });
	}

	const yesNoRecordsContainYes = (questionCode: string) => {
		const questionItem = pickListItems.find((p: PickListItemResponseDTO) => p.code === questionCode);
		if (questionItem) {
			const relatedYesNoRecords = yesNoRecords.filter(record => record.questionId === questionItem.id);
			return relatedYesNoRecords;
		} else {
			return [];
		}
	}

	const suspectUnderstandsSfst = yesNoRecordsContainYes('SuspectUnderstandsSfst');
	const hgnDoNothing = yesNoRecordsContainYes('HGNDoNothing');
	const hgnInstructions = yesNoRecordsContainYes('HGNInstructions');
	const watDoNothing = yesNoRecordsContainYes('WalkAndTurnDoNothing');
	const watInitialFootPosition = yesNoRecordsContainYes('WalkAndTurnInitialFootPosition');
	const watInstructions = yesNoRecordsContainYes('WalkAndTurnInstructions');
	const olsDoNothing = yesNoRecordsContainYes('OneLegStandDoNothing');
	const olsInstructions = yesNoRecordsContainYes('OneLegStandInstructions');

	const pupilSizeInstructions = yesNoRecordsContainYes('PupilSizeInstructions');
	const locInstructions = yesNoRecordsContainYes('LocInstructions');
	const ftnInstructions = yesNoRecordsContainYes('FingerToNoseInstructions');
	const mrbInstructions = yesNoRecordsContainYes('ModifiedRombergInstructions');

	const getMrbTestSwayDirections = (pickListTypeCode = 'SwayDirections') => {
		const swayDirections = pickListItems.filter(item => item.pickListTypeCode === pickListTypeCode);
		return swayDirections;
	}

	const getName = (info: SubjectInformationDTO) => {
		let name = '';
		if (info.firstName && info.lastName) {
			name = `${info.firstName} ${info.lastName}`;
		} else if (info.firstName && !info.lastName) {
			name = info.firstName;
		} else if (info.lastName && !info.firstName) {
			name = info.lastName;
		} else {
			name = '__Subject__';
		}
		return name.trimEnd();
	}
	const name = getName(subjectInformation);

	const getCaseStatusCode = (caseStatusId: number): string => {
		const matchedPickListItem = pickListItems.find((p: PickListItemResponseDTO) => p.id === caseStatusId);
		if (matchedPickListItem) {
			return matchedPickListItem.code!;
		}
		return '';
	}
	const checkEditable = (caseDetails?: CaseDTO) => {
		if (caseDetails) {
			return (
				getCaseStatusCode(caseDetails.statusId!) !== 'Draft'
				&& getCaseStatusCode(caseDetails.statusId!) !== 'Finalized'
			);
		}
		else return false;
	}
	const isEditable = checkEditable(caseDetails);

	const finalizedCase = getCaseStatusCode(caseDetails.statusId!) === 'Finalized';

	const finalizeNotAllowed = () => {
		if (caseDetails.opinionAdditionalNotes) {
			return false;
		}
		if (!(subjectInformation.firstName || subjectInformation.lastName) || !caseDetails.subjectDOB) {
			return true;
		}
		return false;
	}
	// Should we add alert that tells user they won't be able to edit case anymore once finalized??
	const finalizeCase = () => {
		const finalizedStatusItem = pickListItems.find((p: PickListItemResponseDTO) => p.code === 'Finalized');
		const updatingCase = { ...caseDetails, statusId: finalizedStatusItem?.id };
		setIsLoading(true);
		caseAPI.postCase(updatingCase)
			.then((res) => {
				setIsLoading(false);
				setCaseDetails(res);
				setIsSaveToastOpen(true);
			});
	}
	const onSaveToastClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setIsSaveToastOpen(false);
	};

	return (
		<Page isLoading={isLoading}>
			<Title sx={{ displayPrint: 'none' }}>Case Details</Title>
			<Box display='flex'>
				<Box height='80px' display='none' displayPrint='block' sx={{ mr: 1 }} ><img src={tenantSettings.logoUrl} alt="logo" height='100%' /></Box>
				<T variant='h1' alignSelf='center' sx={{ mt: 3 }}>{caseDetails?.caseIdentifier}</T>
			</Box>
			<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }} >
				<Tabs value={currentTab}
					sx={{
						borderRight: 1, borderColor: 'divider', minWidth: 245, position: 'fixed', displayPrint: 'none',
						'& .MuiTab-root': {
							textAlign: 'left',
							p: 0,
							minHeight: 38,
							fontSize: '0.8rem',
							alignItems: 'flex-start'
						}
					}}
					orientation='vertical'
					onChange={onTabChange}>
					{finalizedCase &&
						<Tab value={currentTab}
							label={
								<Box sx={{ pr: 2, mb: 3, width: '100%' }}>
									<PdfDownloadOption caseId={caseDetails.id!} />
								</Box>
							}
						/>
					}
					{protocolModules.map((module) => (
						<TabLink key={module.moduleEnumString} label={module.moduleTitle} value={module.moduleTitle!} />
					))}
				</Tabs>
				<Box sx={{ flexGrow: 1, ml: '245px', '@media print': { ml: 0 } }}>
					<TabPanel currentTab={currentTab} value='caseInfo' >
						<CaseDetailInformation
							caseDetails={caseDetails}
							subjectInformation={subjectInformation}
							isEditable={isEditable}
							onNotesChanged={onCaseDetailsNotesChanged}
						/>
					</TabPanel>
					{protocolModules.map((module) => (
						<TabPanel key={module.moduleEnumString} value={module.moduleTitle!} currentTab={currentTab} >
							{module.moduleEnumString === ProtocolModuleTitle.SfstSetup && (
								<CaseSFSTSetup
									caseInfo={caseDetails}
									caseTextOnChange={onCaseDetailsNotesChanged}
									sfstSetup={sfstSetup}
									subjectName={name}
									suspectUnderstandsSfst={suspectUnderstandsSfst}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.InitialInterview && (
								<CaseInitialInterview
									caseInfo={caseDetails}
									subjectInformation={subjectInformation}
									subjectName={name}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.DriveAndStop && (
								<CaseNarrativeDrivingAndStop
									caseInfo={caseDetails}
									caseObservations={caseObservations}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.HorizontalGaze && (
								<CaseDetailHGN
									caseInfo={caseDetails}
									hgnDoNothing={hgnDoNothing}
									hgnInstructions={hgnInstructions}
									hgnTestResults={hgnTests}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.WalkAndTurn && (
								<CaseDetailWAT
									caseInfo={caseDetails}
									watTestResult={watTests}
									watTestResultItems={watTestItems}
									watDoNothing={watDoNothing}
									watInitialFootPosition={watInitialFootPosition}
									watInstructions={watInstructions}
									submittedCase={isEditable}
									subjectName={name}
									onNotesChanged={onCaseDetailsNotesChanged}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.OneLegStand && (
								<CaseDetailOLS
									caseInfo={caseDetails}
									olsDoNothing={olsDoNothing}
									olsInstructions={olsInstructions}
									olsTestErrors={olsTestErrors}
									olsTestResult={olsTests}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.LOC && (
								<CaseDetailLOC
									caseInfo={caseDetails}
									locInstructions={locInstructions}
									locTestResults={locTests}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}

								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.MRB && (
								<CaseDetailMRB
									caseInfo={caseDetails}
									mrbInstructions={mrbInstructions}
									mrbTestResults={mrbTests}
									mrbTestItemResults={mrbTestItems}
									swayDirections={getMrbTestSwayDirections()}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.FTN && (
								<CaseDetailFTN
									caseInfo={caseDetails}
									ftnInstructions={ftnInstructions}
									ftnTestResults={ftnTests}
									ftnTestItemsResults={ftnTestItems}
									ftnTestTimelineResults={ftnTestTimeline}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.PupilSize && (
								<CaseDetailPupilSize
									caseInfo={caseDetails}
									pupilSizeInstructions={pupilSizeInstructions}
									pupilSizeTestResults={pupilSizeTests}
									onNotesChanged={onCaseDetailsNotesChanged}
									subjectName={name}
									submittedCase={isEditable}
									order={module.order!}
								/>
							)}
							{module.moduleEnumString === ProtocolModuleTitle.Narrative && (
								<CaseNarrativeOpinion
									caseInfo={caseDetails}
									onCaseTextChange={onCaseDetailsNotesChanged}
									submittedCase={isEditable}
									subjectName={name}
									isOpinionAdditionalNotesRequired={finalizeNotAllowed()}
									order={module.order!}
								/>
							)}
						</TabPanel>
					))}
					{isEditable &&
						<Button variant='contained' color='secondary' sx={{ my: 4, ml:3 }} disabled={finalizeNotAllowed()} onClick={finalizeCase}>
							Sign and complete
						</Button>
					}
				</Box>
			</Box>

			<Snackbar open={isSaveToastOpen} autoHideDuration={6000} onClose={onSaveToastClose}>
				<Alert onClose={onSaveToastClose} severity="success" sx={{ width: '100%' }}>
					Case finalized!
				</Alert>
			</Snackbar>
		</Page>
	);
}
export default CaseDetailPage;

interface ITabProps {
	label: React.ReactNode;
	value: string;
}
const TabLink = (props: ITabProps) => {
	return (<Tab label={props.label} value={props.value} href={`#${props.value}`} component='a' />)
}

interface ITabPanelProps {
	children?: React.ReactNode;
	currentTab: string | Number;
	value: string;
}
const TabPanel = (props: ITabPanelProps) => {
	const { value, children } = props;
	return (
		<React.Fragment>
			{/* {(currentTab === value) && */}
			<div id={value}>
				<Box sx={{ p: 3, '@media print': { px: 0 } }}>{children}</Box>
			</div>
			{/* } */}
		</React.Fragment>
	);
}

interface IProps {
	caseId: string;
}
const PdfDownloadOption = (props: IProps) => {
	const [isPdfAvailable, setIsPdfAvailable] = useState(false);
	const [shouldPollForPdf, setShouldPollForPdf] = useState(true);

	useEffect(() => {
		const fetchPdfExists = async () => {
			const exists = await caseAPI.getCasePdfExists(props.caseId);
			if (exists) {
				setIsPdfAvailable(true);
				setShouldPollForPdf(false);
				clearInterval(intervalId);
			}
		};

		let intervalId: NodeJS.Timeout;
		if (shouldPollForPdf) {
			intervalId = setInterval(fetchPdfExists, 5000)
		}

		return () => {
			if (intervalId) clearInterval(intervalId)
		}
	}, [props.caseId, shouldPollForPdf]);

	const download = async () => {
		const url = await caseAPI.getCasePdfUrl(props.caseId);
		window.open(url);
	}

	return (
		<Button variant='outlined' color='primary' fullWidth onClick={download}>
			{isPdfAvailable ? 'Download PDF' : <><CircularProgress size='20px' thickness={2} />  Loading PDF</>}
		</Button>
	);
};