import React from 'react';
import T from '@mui/material/Typography';

interface IProps {
	dateUpdated: string;
	formatter: (date: string) => string;
}
const DateUpdated = (props: IProps) => {
	return (
		<T fontSize='small' fontStyle='italic' >{props.formatter(props.dateUpdated)}</T>
	);
}
export default DateUpdated;