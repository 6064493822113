import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const protocolModuleAPI = {
	getProtocolModules: () => {
		return axios
			.get(`${apiUrl}/tenant/settings`)
			.then((response) => response.data);
	},
};

export default protocolModuleAPI;
