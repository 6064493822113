import React from "react";
import T from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import YesNoAnswerList from "../../YesNoAnswerList";
import DateUpdated from "../../DateUpdated";
import useDateFormatter from "common/useDateFormatter";
import { useTranslation } from "react-i18next";
import {
	extractFingerToNoseNotesData,
	extractTouchLocations,
	mergeFingerToNoseData,
} from "./ftnUtils";
import FingerToNoseFace from "./FingerToNoseFace";
import { CheckIcon } from "./CheckIcon";
import { CloseIcon } from "./CloseIcon";

interface IProps {
	caseInfo: CaseDTO;
	ftnTestResults: FingerToNoseTestDTO[];
	ftnTestItemsResults: FingerToNoseTestItemsDTO[];
	ftnTestTimelineResults: FingerToNoseTestTimelineDTO[];
	ftnInstructions: YesNoRecordDTO[];
	onNotesChanged: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	>;
	submittedCase: boolean;
	subjectName: string;
	order: number;
}

const CaseDetailFTN = (props: IProps) => {
	const {
		ftnTestResults,
		ftnTestItemsResults,
		ftnTestTimelineResults,
		ftnInstructions,
		caseInfo,
		subjectName,
		submittedCase,
		order,
	} = props;

	const { formatTime, formatDateTime } = useDateFormatter(
		caseInfo.ianaTimeZone
	);

	const { t } = useTranslation("ftn");

	const normalizedData = mergeFingerToNoseData(
		ftnTestTimelineResults,
		ftnTestItemsResults
	);
	const missedTouchLocations = extractTouchLocations(normalizedData);
	const ftnNotes = extractFingerToNoseNotesData(normalizedData);

	return (
		<>
			<T variant="h2">{`${order}. Finger to Nose`}</T>
			{ftnTestResults.map((data, index) => (
				<div className="pt-3" key={index}>
					<T variant="h3">
						{data.resetDate
							? `Incomplete Attempt ${index + 1}`
							: "Completed Attempt"}
					</T>
					<T variant="h4">Setup</T>
					<T variant="h6" component="h5">
						Response to Instructions:{" "}
					</T>
					<T fontStyle="italic" sx={{ mt: 2 }}>
						{t(
							"FingerToNose.setup.instructionsLine",
							"Stand with your feet together and your arms at your side.\nWhen I tell you, tilt your head back slightly and close your eyes\nAt my direction, touch the tip of your nose with the tip of your index finger and return to the start position.\n(demonstrate)"
						)}
					</T>
					<YesNoAnswerList
						answers={ftnInstructions}
						testId={data.id}
						subjectName={subjectName}
						timeFormatter={formatTime}
					/>
					<T variant="h4" sx={{ mt: 5 }}>
						Test
					</T>
					<div>
						<T variant="h6" component="h5">
							Results:{" "}
						</T>
						<TableContainer sx={{ mt: 2 }}>
							<Table>
								<TableHead
									sx={{
										"& .MuiTableCell-head": {
											fontWeight: 700,
											borderBottom: 2,
											textTransform: "uppercase",
											paddingBottom: 1,
										},
									}}
								>
									<TableRow>
										<TableCell sx={{ position: "relative", right: "14px" }}>
											Attempt #
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>Notes</TableCell>
										<TableCell sx={{ textAlign: "right" }}>
											Target Hit
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{normalizedData.map((data: any, index) => {
										const label = [1, 3, 6].includes(data.attemptNumber)
											? "Left"
											: [2, 4, 5].includes(data.attemptNumber)
											? "Right"
											: "";

										return (
											<TableRow key={data.id}>
												<TableCell>{`${data.attemptNumber} ${label}`}</TableCell>
												<TableCell align="center">
													{ftnNotes.split("\n").map((line, lineIndex) => (
														<div key={lineIndex}>
															{lineIndex === index && line.trim() === "-" ? (
																<p>-</p>
															) : (
																line
																	.split(",")
																	.map((note, noteIndex) => (
																		<p key={`${lineIndex}-${noteIndex}`}>
																			{lineIndex === index && note.trim()}
																		</p>
																	))
															)}
														</div>
													))}
												</TableCell>
												<TableCell align="right">
													{data.hit ? <CheckIcon /> : <CloseIcon />}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<T sx={{ display: "flex", flexDirection: "column", pb: 1 }}>
						<T sx={{ pt: 4 }}>
							<strong>Missed Attempt Locations </strong>
						</T>
						<FingerToNoseFace savedCirclePositions={missedTouchLocations} />
					</T>

					{data.resetDate && (
						<>
							<T variant="h4">Restarted: </T>
							<T>
								The test was restarted at{" "}
								<strong>{formatDateTime(data.resetDate)}</strong>
							</T>
							<T>
								<strong>Restart Notes: </strong>
								{data.resetNote || (
									<T fontStyle="italic">No restart notes added.</T>
								)}
							</T>
						</>
					)}
				</div>
			))}
			<T variant="h3">Other Indicia</T>
			{submittedCase ? (
				<TextField
					multiline
					rows={9}
					fullWidth
					name="hgnTestAdditionalNotes"
					label="Post-Test Comments:"
					value={caseInfo.ftnTestAdditionalNotes}
					onChange={(evt: any) => props.onNotesChanged(evt)}
				/>
			) : (
				<p>
					<strong>Post-Test Comments: </strong>
					{caseInfo.ftnTestAdditionalNotes ? (
						<React.Fragment>
							{caseInfo.ftnTestAdditionalNotes}
							<DateUpdated
								dateUpdated={caseInfo.dateUpdated!}
								formatter={formatTime}
							/>
						</React.Fragment>
					) : (
						<T fontStyle="italic">No post-test comments added.</T>
					)}
				</p>
			)}
		</>
	);
};
export default CaseDetailFTN;
