export enum ProtocolModules {
    SFST = 1,
    ARIDE = 2,
}

export enum ProtocolModuleTitle {
  SfstSetup = "SfstSetup",
  InitialInterview = "InitialInterview",
  DriveAndStop = "DriveAndStop",
  HorizontalGaze = "HorizontalGaze",
  WalkAndTurn = "WalkAndTurn",
  OneLegStand = "OneLegStand",
  LOC = "LOC",
  FTN = "FTN",
  PupilSize = "PupilSize",
  MRB = "MRB",
  Narrative = "Narrative"
}
