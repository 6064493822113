import React, { useContext } from 'react';

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import axios from 'axios';

import AuthContext from './AuthContext';
import authAPI from './authAPI'
import LoadingPage from 'components/common/LoadingPage';
import SettingsContext from 'settings/SettingsContext';
import settingsApi from 'settings/settingsApi';
import LanguageLoader from 'settings/LanguageLoader';

const AuthStore = (props: any) => {
	const [currentUser, setCurrentUser] = React.useState({} as CurrentUserDTO);
	const [tenantSettings, setTenantSettings] = React.useState({} as TenantSettings);
	const [isInitializing, setIsInitializing] = React.useState(true);
	const { settings } = useContext(SettingsContext);
	const { accounts, instance } = useMsal();

	const query = window.location.search;
	const params = new URLSearchParams(query);
	const daemonMode = params.get('daemon') === 'true'

	React.useEffect(() => {
		const getData = async () => {

			if(daemonMode) {

				const token = params.get('token');
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

				const userProm = await authAPI.getCurrentUser();
				const tenantSettingsProm = settingsApi.getTenantSettings();
				const [user, tenantSettings] = await Promise.all([userProm, tenantSettingsProm]);
				
				setCurrentUser(user);
				setTenantSettings(tenantSettings);

				setIsInitializing(false);
			}
			else if(accounts.length === 1 && settings.b2CApiScopes && settings.b2CApiScopes.length > 0) {
				//our setutp may be a bit off.  We are using the IDToken to authenticate, 
				// trying to fetch an access token just gives an empty token				
				// https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react
				const authResult = await instance.acquireTokenSilent({scopes: settings.b2CApiScopes!, forceRefresh:false, account:accounts[0]});
				// 	if(token.accessToken.length === 0){
				// 		await instance.acquireTokenRedirect({scopes: [], account:account[0]});
				// 	}
				axios.defaults.headers.common['Authorization'] = `Bearer ${authResult.accessToken}`;

				const userProm = authAPI.getCurrentUser();
				const tenantSettingsProm = settingsApi.getTenantSettings();
				const [user, tenantSettings] = await Promise.all([userProm, tenantSettingsProm]);

				setCurrentUser(user);
				setTenantSettings(tenantSettings);

				setIsInitializing(false);
				//}
			}
		}

		getData();

	}, [accounts, instance, settings.b2CApiScopes, daemonMode]);

	const initialState = {
		currentUser,
		isInitializing,
		tenantSettings
	};

	return (
		<>{daemonMode 
			? <AuthContext.Provider value={initialState}>
				{initialState.isInitializing 
					? (<LoadingPage />) 
					: 
					<>
						<LanguageLoader />
						{props.children}
					</>
				}
			</AuthContext.Provider>
			: 
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<AuthenticatedTemplate>
					<AuthContext.Provider value={initialState}>
						{ initialState.isInitializing 
							? (<LoadingPage />) 
							: 
							<>
								<LanguageLoader />
								{props.children}
							</>
						}
					</AuthContext.Provider>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<LoadingPage />
				</UnauthenticatedTemplate>
			</MsalAuthenticationTemplate>
		}
		</>
	);
};
export default AuthStore;
