import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const caseAPI = {
	getAllCases: (request: AllCasesRequest) => {
		return axios.get(`${apiUrl}/cases`, { params: request })
			.then((response) => response.data === "" ? [] : response.data as CaseDTO[]);
	},
	getCasesReports: (getForReports?: boolean, startDate?: Date, endDate?: Date) => {
		const data = {
			params: {
				getForReports: getForReports || false,
				startDate: startDate || null,
				endDate: endDate || null
			}
		};
		return axios.get(`${apiUrl}/cases/report`, data)
			.then((response) => response.data === "" ? [] : response.data as CaseReportDTO[]);
	},
	// only for demo
	getDemoCasesReports: () => {
		return axios.get(`${apiUrl}/cases/report-demo`)
			.then((response) => response.data === "" ? [] : response.data as CaseReportDTO[]);
	},
	getCase: (Id: string) => {
		return axios.get(`${apiUrl}/cases/${Id}`)
			.then((response) => response.data as CaseDTO);
	},
	postCase: (request: PostCaseRequest) => {
		return axios.post(`${apiUrl}/cases`.concat(request.id ? `/${request.id}` : ``), request)
			.then((response) => response.data as CaseDTO);
	},
	getCasesSummaryReport: () => {

		return axios.get(`${apiUrl}/cases/report/testsummary`)
			.then((response) => response.data === "" ? [] : response.data as CaseSummaryReportDTO[]);
	},
	getCasePdfExists: async (caseId: string) => {
		var response = await axios.get(`${apiUrl}/cases/${caseId}/pdfExists`);
		return response.data as boolean
	},
	getCasePdfUrl: async (caseId: string) => {
		var response = await axios.get(`${apiUrl}/cases/${caseId}/pdfDownload`);
		return response.data as string
	}
};

export default caseAPI;