enum FingerToNoseStringProperties {
	eyelidTremor = "Eyelid Tremors",
	legTremor = "Leg Tremors",
	startEarly = "Started Early",
	touchedWithWrongFinger = "Wrong Finger",
	touchedWithWrongHand = "Wrong Hand",
	touchedWithPad = "Pad of Finger",
	loseBalance = "Lost Balance",
}

interface MergedFingerToNoseData {
	attemptNumber: number;
	caseId: string;
	fingerToNoseTestId: string;
	id: string;
	hit: boolean;
	legTremor?: boolean;
	loseBalance?: boolean;
	eyelidTremor?: boolean;
	touchLocation?: string;
	touchedWithWrongFinger?: boolean;
	touchedWithWrongHand?: boolean;
	touchedWithPad?: boolean;
	startEarly?: boolean;
}

export const extractFingerToNoseNotesData = (
	ftnData: MergedFingerToNoseData[] | any
): string => {
	if (!Array.isArray(ftnData)) {
		return "-";
	}

	const results: string[] = [];

	ftnData.forEach((data: MergedFingerToNoseData) => {
		const result: string[] = [];

		for (const [property, description] of Object.entries(
			FingerToNoseStringProperties
		)) {
			const value = data[property as keyof MergedFingerToNoseData];

			if (value) {
				if (Array.isArray(value)) {
					value.forEach(() => result.push(description));
				} else {
					result.push(description);
				}
			}
		}

		results.push(result.length > 0 ? result.join(", ") : "-");
	});

	return results.join("\n");
};

export const extractTouchLocations = (ftnData: MergedFingerToNoseData[]) => {
	const touchLocations: Record<number, { x: number; y: number }> = {};

	ftnData.forEach(
		(entry: { touchLocation: string; attemptNumber: number } | any) => {
			if (entry.touchLocation) {
				try {
					const location = JSON.parse(entry.touchLocation);
					if (
						typeof location.x === "number" &&
						typeof location.y === "number"
					) {
						touchLocations[entry.attemptNumber] = {
							x: location.x,
							y: location.y,
						};
					}
				} catch (error) {
					console.error("Failed to parse touchLocation:", error);
				}
			}
		}
	);

	return Object.entries(touchLocations).map(([attemptNumber, location]) => ({
		attemptNumber: Number(attemptNumber),
		...location,
	}));
};
export const mergeFingerToNoseData = (
	timelineData: FingerToNoseTestTimelineDTO[],
	testItemsData: FingerToNoseTestItemsDTO[]
): MergedFingerToNoseData[] => {
	const timelineMap: Record<string, Partial<MergedFingerToNoseData>> = {};

	timelineData.forEach((item) => {
		const { fingerToNoseTestItemId, ...rest } = item;
		if (fingerToNoseTestItemId) {
			timelineMap[fingerToNoseTestItemId] = {
				...timelineMap[fingerToNoseTestItemId],
				...rest,
			};
		}
	});

	testItemsData.forEach((item) => {
		const existing = timelineMap[item.id!];
		if (existing) {
			timelineMap[item.id!] = { ...existing, ...item };
		} else {
			timelineMap[item.id!] = item;
		}
	});

	const combinedMap: Record<number, Partial<MergedFingerToNoseData>> = {};

	Object.values(timelineMap).forEach((item) => {
		const attemptNumber = item.attemptNumber || 0;
		if (!combinedMap[attemptNumber]) {
			combinedMap[attemptNumber] = { ...item };
		} else {
			const existing = combinedMap[attemptNumber];
			combinedMap[attemptNumber] = {
				...existing,
				...item,
				loseBalance: existing.loseBalance || item.loseBalance,
				eyelidTremor: existing.eyelidTremor || item.eyelidTremor,
				legTremor: existing.legTremor || item.legTremor,
				touchedWithPad: existing.touchedWithPad || item.touchedWithPad,
				touchedWithWrongHand:
					existing.touchedWithWrongHand || item.touchedWithWrongHand,
				touchedWithWrongFinger:
					existing.touchedWithWrongFinger || item.touchedWithWrongFinger,
				startEarly: existing.startEarly || item.startEarly,
				hit: existing.hit || item.hit,
				touchLocation: item.touchLocation || existing.touchLocation,
			};
		}
	});

	const combinedData: MergedFingerToNoseData[] = Object.values(
		combinedMap
	) as MergedFingerToNoseData[];
	return combinedData.sort(
		(a, b) => (a.attemptNumber || 0) - (b.attemptNumber || 0)
	);
};
