import React from "react";

import T from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import YesNoAnswerList from "../../YesNoAnswerList";
import DateUpdated from "../../DateUpdated";
import useDateFormatter from "common/useDateFormatter";
import { useTranslation } from "react-i18next";

interface IProps {
	caseInfo: CaseDTO;
	pupilSizeTestResults: PupilSizeTestDTO[];
	pupilSizeInstructions: YesNoRecordDTO[];
	onNotesChanged: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	>;
	submittedCase: boolean;
	subjectName: string;
	order:number;
}

const CaseDetailPupilSize = (props: IProps) => {
	const {
		pupilSizeTestResults,
		pupilSizeInstructions,
		caseInfo,
		subjectName,
		submittedCase,
		order,
	} = props;

	const { formatTime, formatDateTime } = useDateFormatter(
		caseInfo.ianaTimeZone
	);

	const { t } = useTranslation("pupilSize");

	return (
		<>
			<T variant="h2">{`${order}. Pupil Size`}</T>
			{pupilSizeTestResults.map((data, index) => (
				<div className="pt-3" key={index}>
					<T variant="h3">
						{data.resetDate
							? `Incomplete Attempt ${index + 1}`
							: "Completed Attempt"}
					</T>
					<T variant="h4">Setup</T>
					<T variant="h6" component="h5">
						Response to Instructions:{" "}
					</T>
					<T fontStyle="italic" sx={{ mt: 2 }}>
						{t(
							"PupilSize.setupInstructions",
							"I’m going to check your eyes, please keep your head still and look straight ahead."
						)}
					</T>
					<YesNoAnswerList
						answers={pupilSizeInstructions}
						testId={data.id}
						subjectName={subjectName}
						timeFormatter={formatTime}
					/>
					<T variant="h4" sx={{ mt: 5 }}>
						Test
					</T>
					<T variant="h6" component="h5">
						Results:{" "}
					</T>
					<TableContainer sx={{ mb: 5 }}>
						<Table>
							<TableHead
								sx={{
									"& .MuiTableCell-head": {
										fontWeight: 700,
										borderBottom: 2,
										textTransform: "uppercase",
										paddingBottom: 1,
									},
								}}
							>
								<TableCell sx={{position: "relative", right: "14px"}}>Eye</TableCell>
								<TableCell sx={{position: "relative", right: "14px"}}>Pupil State</TableCell>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										Right
									</TableCell>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										{data.rightEyeSize}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										Left
									</TableCell>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										{data.leftEyeSize}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<T sx={{ mb: 4 }}>
						<div>
							<b>Additional Notes: </b>
						</div>
						{data.additionalNotes
							? data.additionalNotes
							: "No additional notes"}
					</T>
					<TableContainer sx={{ mb: 5 }}>
						<Table>
							<TableHead
								sx={{
									"& .MuiTableCell-head": {
										fontWeight: 700,
										borderBottom: 2,
										textTransform: "uppercase",
										paddingBottom: 1,
									},
								}}
							>
								<TableRow>
									<TableCell sx={{position: "relative", right: "14px"}}>Environment Lighting</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.streetlightLighting && (
									<TableRow>
										<TableCell sx={{position: "relative", right: "14px"}}>Streetlight</TableCell>
									</TableRow>
								)}
								{data.flashlightLighting && (
									<TableRow>
										<TableCell sx={{position: "relative", right: "14px"}}>Flashlight</TableCell>
									</TableRow>
								)}
								{data.naturalLighting && (
									<TableRow>
										<TableCell sx={{position: "relative", right: "14px"}}>Natural Light</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>

					{data.resetDate && (
						<>
							<T variant="h4">Restarted: </T>
							<T>
								The test was restarted at{" "}
								<strong>{formatDateTime(data.resetDate)}</strong>
							</T>
							<T>
								<strong>Restart Notes: </strong>
								{data.resetNote || (
									<T fontStyle="italic">No restart notes added.</T>
								)}
							</T>
						</>
					)}
				</div>
			))}
			<T variant="h3">Other Indicia</T>
			{submittedCase ? (
				<TextField
					multiline
					rows={9}
					fullWidth
					name="hgnTestAdditionalNotes"
					label="Post-Test Comments:"
					value={caseInfo.pupilSizeTestAdditionalNotes}
					onChange={(evt: any) => props.onNotesChanged(evt)}
				/>
			) : (
				<p>
					<strong>Post-Test Comments: </strong>
					{caseInfo.pupilSizeTestAdditionalNotes ? (
						<React.Fragment>
							{caseInfo.pupilSizeTestAdditionalNotes}
							<DateUpdated
								dateUpdated={caseInfo.dateUpdated!}
								formatter={formatTime}
							/>
						</React.Fragment>
					) : (
						<T fontStyle="italic">No post-test comments added.</T>
					)}
				</p>
			)}
		</>
	);
};
export default CaseDetailPupilSize;