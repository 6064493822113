import { Button, Menu, MenuItem } from "@mui/material";
import AuthorizationWrapper from "auth/AuthorizationWrapper";
import { Permissions } from 'auth/Permissions';
//import { Link } from 'react-router-dom'
import Link from '@mui/material/Link';
import React from "react";

const AdminMenu = () => {

  const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  return (
    <React.Fragment>
      <Button key='admin' onClick={handleOpenAdminMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
        Admin
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElAdmin}
        open={Boolean(anchorElAdmin)}
        onClose={handleCloseAdminMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button', }}
      >
        <AuthorizationWrapper permissions={[Permissions.ReadUser, Permissions.WriteUser]}>
          <MenuItem onClick={handleCloseAdminMenu} component={Link} href='/admin/users'>
            Users
          </MenuItem>
        </AuthorizationWrapper>
        <AuthorizationWrapper permissions={[Permissions.ReadRole, Permissions.WriteRole]}>
          <MenuItem onClick={handleCloseAdminMenu} component={Link} href='/admin/roles'>
            Roles
          </MenuItem>
        </AuthorizationWrapper>
        <AuthorizationWrapper permissions={[Permissions.ReadPickList, Permissions.WritePickList]}>
          <MenuItem component={Link} href='/admin/pick-lists'>
            Pick lists
          </MenuItem>
        </AuthorizationWrapper>
		<AuthorizationWrapper permissions={[Permissions.WriteTenant]}>
          <MenuItem component={Link} href='/admin/settings'>
            Settings
          </MenuItem>
        </AuthorizationWrapper>
		<AuthorizationWrapper permissions={[Permissions.ReadDemoUsage]}>
		  <MenuItem component={Link} href='/admin/demo-usage'>
			Demo Usage
		  </MenuItem>
		</AuthorizationWrapper>
      </Menu>
    </React.Fragment>
  );
}

export default AdminMenu;