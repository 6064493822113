import React from 'react';

import T from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import YesNoAnswerList from './YesNoAnswerList';
import DateUpdated from './DateUpdated';
import useDateFormatter from 'common/useDateFormatter';
import { useTranslation } from 'react-i18next';

interface IProps {
	caseInfo: CaseDTO;
	hgnTestResults: HorizontalGazeTestDTO[];
	hgnDoNothing: YesNoRecordDTO[];
	hgnInstructions: YesNoRecordDTO[];
	onNotesChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	submittedCase: boolean;
	subjectName: string;
	order: number;
}

const CaseDetailHGN = (props: IProps) => {
	const { hgnTestResults, hgnDoNothing, hgnInstructions, caseInfo, subjectName, submittedCase, order } = props;

	const {formatTime, formatDateTime} = useDateFormatter(caseInfo.ianaTimeZone);
	
	const { t } = useTranslation('hgn');
	
	const renderLightingAnswers = (record: HorizontalGazeTestDTO) => {
		let answer = '';
		if (record.naturalLighting) {
			answer += 'Natural, ';
		}
		if (record.flashlightLighting) {
			answer += 'Flashlight, ';
		}
		if (record.streetlightLighting) {
			answer += 'Streetlight, ';
		}
		
		return answer.length > 0 ? answer.slice(0, answer.length-2) : '';
	}
	const renderBoolean = (value: boolean | undefined) => {
		if (value === undefined) {
			return '-';
		}
		return value ? <strong>Yes</strong> : 'No';
	}

	return (
		<>
			<T variant='h2'>{`${order}. Horizontal Gaze Nystagmus`}</T>
			{hgnTestResults.map((hgn, index) => (
				<div className="pt-3" key={index}>
					<T variant='h3'>{hgn.resetDate ? `Incomplete Attempt ${index + 1}` : 'Completed Attempt'}</T>
					<T variant='h4'>Setup</T>
					<T variant='h6' component='h5'>Response to Instructions: </T>
					<T fontStyle='italic'>
						{t('demand1',
						'Please stand with your heels and toes together, and your arms at your sides. (demonstrate) Do not do anything until I tell you to. Do you understand?'
						)}
					</T>
					<YesNoAnswerList answers={hgnDoNothing} testId={hgn.id} subjectName={subjectName} timeFormatter={formatTime} />
					<T fontStyle='italic'>
						{t('demand2',
						'I am going to check your eyes. Please remove your glasses (If wearing any). Are you wearing contacts?'
						)}
					</T>
					<ul className="mb-3 pb-2">
						<li>
							<strong>{subjectName}</strong> {hgn.wearingGlasses ? 'was' : 'was not'} wearing glasses.
						</li>
						<li>
							<strong>{subjectName}</strong> {hgn.wearingContact ? 'was' : 'was not'} wearing hard contact lenses.
						</li>
						<li>
							<strong>{subjectName}</strong> {hgn.pupilEqualTracking ? 'had equal' : 'had unequal'} pupil tracking.
						</li>
						<li>
							<strong>{subjectName}</strong> {hgn.pupilEqualSize ? 'had equal' : 'had unequal'} pupil size.
						</li>
					</ul>
					<T fontStyle='italic'>
						{t('demand3',
						'Please look at the top of the stimulus. Keep your head still, and follow the top of the stimulus when I move it. Only move your eyes, not your head. Do you understand? (Check horizontal first, then vertical).'
						)}
					</T>
					<YesNoAnswerList answers={hgnInstructions} testId={hgn.id} subjectName={subjectName} timeFormatter={formatTime} />
					<p className="mb-3 pb-2">
						<strong>Lighting Used: </strong>
						{renderLightingAnswers(hgn)}
					</p>
					<p className="mb-3 pb-2">
						<strong>Additional Notes: </strong>
						{hgn.notes ? hgn.notes : <T fontStyle='italic'>No additional notes added.</T>}
					</p>
					<T variant='h4'>Test</T>
					<T variant='h6' component='h5'>Results: </T>
					<TableContainer sx={{mb: 5}}>
					<Table>
						<TableHead sx={{ '& .MuiTableCell-head': {fontWeight: 700, borderBottom: 2, textTransform: 'uppercase', paddingBottom: 1} }}>
							<TableRow>
								<TableCell>Indicators</TableCell>
								<TableCell align='center'>Left Eye</TableCell>
								<TableCell align='center'>Right Eye</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Lack of smooth pursuit</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.lackOfSmoothLeft)}</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.lackOfSmoothRight)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Distinct and Sustained Nystagmus at Maximum Deviation</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.maximumDeviationLeft)}</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.maximumDeviationRight)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Onset of Nystagmus Prior to 45 Degrees</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.onsetPriorLeft)}</TableCell>
								<TableCell align='center'>{renderBoolean(hgn.onsetPriorRight)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Vertical Nystagmus</TableCell>
								<TableCell colSpan={2} align='center'>{renderBoolean(hgn.verticalNystagmus)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					</TableContainer>

					{hgn.resetDate ? (
						<>
							<T variant='h4'>Restarted: </T>
							<T>
								The test was restarted at <strong>{formatDateTime(hgn.resetDate)}</strong>
							</T>
							<T>
								<strong>Restart Notes: </strong>
								{hgn.resetNote || <T fontStyle='italic'>No restart notes added.</T>}
							</T>
						</>
					) : (
						<T variant='h4'>Decision Points: {hgn.decisionPoint}/6</T>
					)}
				</div>
			))}
			<T variant='h3'>Other Indicia</T>
			{submittedCase ?
				(
					<TextField
						multiline
						rows={9}
						fullWidth
						name="hgnTestAdditionalNotes"
						label="Post-Test Comments:"
						value={caseInfo.hgnTestAdditionalNotes}
						onChange={(evt: any) => props.onNotesChanged(evt)}
					/>
				) :
				(
					<p>
						<strong>Post-Test Comments: </strong>
						{caseInfo.hgnTestAdditionalNotes ? 
							<React.Fragment>
								{caseInfo.hgnTestAdditionalNotes}
								<DateUpdated dateUpdated={caseInfo.dateUpdated!} formatter={formatTime} />
							</React.Fragment>
						:
							<T fontStyle='italic'>No post-test comments added.</T>
						}
					</p>
				)
			}
		</>
	);
}
export default CaseDetailHGN;