export function getItemLabel(
	item: ModifiedRombergTestItemDTO,
	swayDirections: PickListItemResponseDTO[]
) {
	if (item.loseBalance) {
		return "Lost balance";
	}
	if (item.eyeTremor) {
		return "Eye tremor";
	}
	if (item.legTremor) {
		return "Leg tremor";
	}
	if (item.swayId) {
		return findSwayDirection(item.swayId, swayDirections);
	}
	return "Unknown";
}

export function findSwayDirection(
	id: number,
	swayDirections: PickListItemResponseDTO[] = []
) {
	const direction = swayDirections.find((i) => i.id === id);
	return direction ? direction.description + " sway" : undefined;
}

export function getItemSeverity(item: ModifiedRombergTestItemDTO) {
	if(item.eyeTremor || item.legTremor || item.loseBalance) {
		return "-";
	}
	if (item.severe === true) {
		return "2-5 in";
	}
	if (item.severe === false) {
		return "1 in";
	}
	return "-";
}

export function formatTimeFromSeconds(seconds:number) {
    // Calculate minutes and seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    
    // Format minutes and seconds
    const formattedMinutes = minutes > 0 ? minutes.toString() : '00';
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds.toString();
    
    // Return formatted time
    return `${formattedMinutes}:${formattedSeconds}`;
}


export function getMinutesAndSeconds(timestamp: string ) {
	const date = new Date(timestamp);

	const minutes = date.getUTCMinutes().toString().padStart(2, "0");
	const seconds = date.getUTCSeconds().toString().padStart(2, "0");

	if (minutes === '00' && seconds !== '00') {
		return `00:${seconds}`;
	  }


	return `${minutes}:${seconds}`;
}
