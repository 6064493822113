//TODO: 
//		decide if we need the seperate search box, it does seem nicer
// 		fix loading height issues
import React, { useContext, useEffect, useState } from 'react';
import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRowParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import DataGrid from 'components/common/StripedDataGrid';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import caseAPI from './caseAPI';
import PickListContext from 'pickList/pickListContext';
import { useHistory } from 'react-router-dom';
import { Switch, FormGroup, FormControlLabel, Grid } from '@mui/material';
import useDateFormatter from 'common/useDateFormatter';
import useQuerystring from 'common/useQuerystring';

const DEFAULT_PAGE_SIZE = 50;
const LOCAL_PAGE_SIZE = 'sfst-casePageSize'; // the key to use in local storage for the page size
const CaseListingPage = () => {
	const [cases, setCases] = useState<CaseDTO[]>([]);
	const [showTestCases, setShowTestCases] = useState(false);
	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const { pickListItems } = useContext(PickListContext);
	const history = useHistory();
	const query = useQuerystring();

	const { formatDateTime } = useDateFormatter();

	useEffect(() => {
		const storedPageSize = window.localStorage.getItem(LOCAL_PAGE_SIZE);
		setPageSize(storedPageSize ? JSON.parse(storedPageSize) : DEFAULT_PAGE_SIZE);
	}, []);
	
	useEffect(() => {
		window.localStorage.setItem(LOCAL_PAGE_SIZE, pageSize.toString());
	}, [pageSize]);

	const setPageNumber = (pageNumber: number) => {
		history.replace(`/cases?page=${pageNumber}`);
	};

	const page = query.get('page') ? parseInt(query.get('page')!) : 0;

	useEffect(() => {
		const fetchCases = async () => {
			const myCases = await caseAPI.getAllCases({isTestCase: showTestCases});
			setCases(myCases);
		};

		fetchCases();
	}, [showTestCases]);

	const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowTestCases(event.target.checked);
	  };
	
	const actions: GridActionsColDef =
	{
		field: 'actions',
		headerName: 'Actions',
		type: 'actions',
		getActions: (params:GridRowParams) => [
			<GridActionsCellItem onClick={() => history.push(`cases/${params.id}`)} label="Edit" showInMenu={false} icon={<ArrowCircleRightOutlinedIcon />} />
		]
	}
	const formatDate = (params: GridValueGetterParams) => {
		if(params.value) {
			const timezone = params.row.ianaTimeZone;
			const date = params.row.date;
			return formatDateTime(date, timezone);
		}
	}
	const getStatusDescription = (params: GridValueGetterParams) => {
		const plItem = pickListItems.find(pl => pl.id === params.row.statusId);
		return plItem ? plItem?.description : 'No data';
	}
	const columns: GridColDef[] = [
		{ field: 'caseIdentifier', headerName: 'Case #', flex: 1 },
		{ 
			field: 'statusId',
			headerName: 'State',
			valueGetter: getStatusDescription
		},
		{ field: 'officerName', headerName: 'Officer', flex: .8 },
		{ field: 'address', headerName: 'Location', flex:2 },
		{ field: 'date', headerName: 'Date', valueGetter:formatDate, width: 150 },
		actions
	];

	return (
		<Page>
			<Title>
				<Grid container spacing={2} >
					<Grid item xs>Case Listing</Grid>
					<Grid item xs={12} sm={5} md={4} xl={2} justifyContent='flex-end'>
						<FormGroup>
							<FormControlLabel control={<Switch checked={ showTestCases } onChange={handleCheckedChange} />} label="Show Test Cases" sx={{marginLeft: 'auto'}}/>
						</FormGroup>
					</Grid>
				</Grid>
			</Title>
			
			<DataGrid
				rows={cases}
				columns={columns}
				autoHeight
				getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
				pageSize={pageSize}
				onPageSizeChange={(size) => setPageSize(size)}
				page={page}
				onPageChange={(page) => setPageNumber(page)}
			/>
		</Page>
	);
}

export default CaseListingPage;