import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from 'auth/AuthContext';
import { useContext } from 'react';
import AuthorizationWrapper from 'auth/AuthorizationWrapper';
import { Permissions } from 'auth/Permissions';
import AdminMenu from './AdminMenu';
import Link from '@mui/material/Link';
import ReportsMenu from './ReportsMenu';
import appIcon from 'assets/images/app-icon.png';
import { useMsal } from '@azure/msal-react';

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { currentUser } = useContext(AuthContext);
  const { instance } = useMsal();

  const logout = () => {
    instance.logoutRedirect();
  }

  return (
    <AppBar position="fixed" sx={{ px:3, displayPrint: 'none', zIndex: (theme) => theme.zIndex.drawer+1}} >      
        <Toolbar disableGutters>
			{/* Web sized menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
		  	<Link href='/' sx={{color:'white'}}><img src={appIcon} alt='Logo' height='65px' /></Link>
            <MenuItem key='Home' href='/' component={Button}>Home</MenuItem>
			<MenuItem key='Cases' href='/cases' component={Button}>Cases</MenuItem>

			<ReportsMenu />
            
            <AuthorizationWrapper
              permissions={[
                Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole,
                Permissions.WriteTenant, Permissions.WritePickList, Permissions.WriteRole,
				Permissions.ReadDemoUsage, Permissions.WriteUser
              ]}
            >
              <AdminMenu />
            </AuthorizationWrapper>
          </Box>

		  {/* Mobile sized menu */}
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            LOGOasdfasdf
          </Typography>		  
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <AuthorizationWrapper
                permissions={[
                  Permissions.ReadTenant, Permissions.ReadPickList, Permissions.ReadRole,
                  Permissions.WriteTenant, Permissions.WritePickList, Permissions.WriteRole
                ]}
              >
                <AdminMenu />
              </AuthorizationWrapper>
            </Menu>
          </Box> */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ color: 'white',  }}>
                <PersonIcon /> {currentUser.firstName}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key='logout' onClick={logout}>
                  <Typography textAlign="center">Logout</Typography>
              </MenuItem>              
            </Menu>
          </Box>
        </Toolbar>
    </AppBar>
  );
};
export default NavBar;