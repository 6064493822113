import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const modifiedRombergTestAPI = {
	getModifiedRombergTest: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/modified-romberg-test`)
			.then((response) => response.data as ModifiedRombergTestDTO[]);
	},
	getModifiedRombergTestItem: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/modified-romberg-test-items`)
			.then((response) => response.data as ModifiedRombergTestItemDTO[]);
	},
};

export default modifiedRombergTestAPI;
