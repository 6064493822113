import React from "react";

import T from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import YesNoAnswerList from "../../YesNoAnswerList";
import DateUpdated from "../../DateUpdated";
import useDateFormatter from "common/useDateFormatter";
import { useTranslation } from "react-i18next";
import EyeTrackingComponent from "./EyeTrackingComponent";

interface IProps {
	caseInfo: CaseDTO;
	locTestResults: LackOfConvergenceTestDTO[];
	locInstructions: YesNoRecordDTO[];
	onNotesChanged: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	>;
	submittedCase: boolean;
	subjectName: string;
	order: number;
}

const CaseDetailLOC = (props: IProps) => {
	const {
		locTestResults,
		locInstructions,
		caseInfo,
		subjectName,
		submittedCase,
		order,
	} = props;

	const { formatTime, formatDateTime } = useDateFormatter(
		caseInfo.ianaTimeZone
	);

	const { t } = useTranslation("loc");

	const renderBoolean = (value: boolean | undefined) => {
		if (value === undefined) {
			return "-";
		}
		return value ? <strong>Yes</strong> : "No";
	};

	return (
		<>
			<T variant="h2">{`${order}. Lack of Convergence`}</T>
			{locTestResults.map((data, index) => (
				<div className="pt-3" key={index}>
					<T variant="h3">
						{data.resetDate
							? `Incomplete Attempt ${index + 1}`
							: "Completed Attempt"}
					</T>
					<T variant="h4">Setup</T>
					<T variant="h6" component="h5">
						Response to Instructions:{" "}
					</T>
					<T fontStyle="italic" sx={{ mt: 2 }}>
						{t(
							"LackOfConvergence.setup.instructionsLine",
							"I'm going to move the stimulus around in a circle, and will move it toward the bridge of your nose. I will not touch your nose. \n\nKeep your head still and focus your eyes on the tip (of the used stimulus) and follow the movement with your eyes only."
						)}
					</T>
					<YesNoAnswerList
						answers={locInstructions}
						testId={data.id}
						subjectName={subjectName}
						timeFormatter={formatTime}
					/>
					<T variant="h4" sx={{ mt: 5 }}>
						Test
					</T>
					<T variant="h6" component="h5">
						Results:{" "}
					</T>
					<T sx={{ mb: 4, pt: 4 }}>
						<strong>Pupil Movement Pattern </strong>
					</T>
					<T sx={{ display: "flex", gap: "15px" }}>
						<EyeTrackingComponent
							eyeSide="right"
							savedCoordinates={data.rightEyeArray}
						/>
						<EyeTrackingComponent
							eyeSide="left"
							savedCoordinates={data.leftEyeArray}
						/>
					</T>
					<TableContainer sx={{ mb: 5, mt: 5 }}>
						<Table>
							<TableHead
								sx={{
									"& .MuiTableCell-head": {
										fontWeight: 700,
										borderBottom: 2,
										textTransform: "uppercase",
										paddingBottom: 1,
									},
								}}
							>
								<TableCell sx={{ position: "relative", right: "14px" }}>
									Indicators
								</TableCell>
								<TableCell></TableCell>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell sx={{ position: "relative", right: "14px" }}>
										Lack of Convergence
									</TableCell>
									<TableCell>{renderBoolean(data.lackOfConvergence)}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<T variant="h4">Additional Info</T>
					<T sx={{ mb: 4 }}>
						<div>
							<b>Additional Notes: </b>
						</div>
						{data.additionalNotes
							? data.additionalNotes
							: "No additional notes"}
					</T>
					{data.resetDate && (
						<>
							<T variant="h4">Restarted: </T>
							<T>
								The test was restarted at{" "}
								<strong>{formatDateTime(data.resetDate)}</strong>
							</T>
							<T>
								<strong>Restart Notes: </strong>
								{data.resetNote || (
									<T fontStyle="italic">No restart notes added.</T>
								)}
							</T>
						</>
					)}{" "}
				</div>
			))}
			<T variant="h3">Other Indicia</T>
			{submittedCase ? (
				<TextField
					multiline
					rows={9}
					fullWidth
					name="hgnTestAdditionalNotes"
					label="Post-Test Comments:"
					value={caseInfo.locTestAdditionalNotes}
					onChange={(evt: any) => props.onNotesChanged(evt)}
				/>
			) : (
				<p>
					<strong>Post-Test Comments: </strong>
					{caseInfo.locTestAdditionalNotes ? (
						<React.Fragment>
							{caseInfo.locTestAdditionalNotes}
							<DateUpdated
								dateUpdated={caseInfo.dateUpdated!}
								formatter={formatTime}
							/>
						</React.Fragment>
					) : (
						<T fontStyle="italic">No post-test comments added.</T>
					)}
				</p>
			)}
		</>
	);
};
export default CaseDetailLOC;
