import React from "react";
import "./FingerToNoseFaceStyles.scss";

interface FTNProps {
	savedCirclePositions: { attemptNumber: number; x: number; y: number }[];
}

const FingerToNoseFace = ({ savedCirclePositions }: FTNProps) => {
	const faceImageSource = require("../../../assets/images/fingerToNoseTestFace.png");

	return (
		<div>
			<div>
				<div className="face-container">
					<img src={faceImageSource} alt="Face" className="face-image" />
					<div className="clickable-overlay">
						<svg width="100%" height="100%" viewBox="0 0 300 300">
							{savedCirclePositions.map((pos, index) => (
								<React.Fragment key={index}>
									<circle cx={pos.x} cy={pos.y} r={15} fill="#446BC0" />
									<text
										x={pos.x}
										y={pos.y + 5}
										fontSize="15"
										fill="white"
										textAnchor="middle"
									>
										{pos.attemptNumber}
									</text>
								</React.Fragment>
							))}
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FingerToNoseFace;
