export const CheckIcon = () => (
	<svg width={24} height={24} viewBox="0 0 24 24" fill="none">
		<defs>
			<clipPath id="clip0">
				<rect width={24} height={24} fill="white" />
			</clipPath>
		</defs>
		<g clipPath="url(#clip0)">
			<path
				d="M8.99989 16.17L5.52989 12.7C5.13989 12.31 4.50989 12.31 4.11989 12.7C3.72989 13.09 3.72989 13.72 4.11989 14.11L8.29989 18.29C8.68989 18.68 9.31989 18.68 9.70989 18.29L20.2899 7.71C20.6799 7.32 20.6799 6.69 20.2899 6.3C19.8999 5.91 19.2699 5.91 18.8799 6.3L8.99989 16.17Z"
				fill="#669D41"
			/>
		</g>
	</svg>
);
