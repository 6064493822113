import { useEffect, useState } from "react";
import { ProtocolModules } from "types/ProtocolModules";
import { fetchAndSetArideData } from "utils/fetchAndSetArideData";

interface ArideDataProps {
	caseId: string;
	protocolId?: number;
}

interface UseArideDataResponse {
	pupilSizeTests: PupilSizeTestDTO[];
	locTests: LackOfConvergenceTestDTO[];
	ftnTests: FingerToNoseTestDTO[];
	ftnTestItems: FingerToNoseTestItemsDTO[];
	ftnTestTimeline: FingerToNoseTestTimelineDTO[];
	mrbTests: ModifiedRombergTestDTO[];
	mrbTestItems: ModifiedRombergTestItemDTO[];
	fetchDataError: any;
}

const useArideData = ({
	caseId,
	protocolId,
}: ArideDataProps): UseArideDataResponse => {
	const [pupilSizeTests, setPupilSizeTests] = useState<PupilSizeTestDTO[]>([]);
	const [locTests, setLocTests] = useState<LackOfConvergenceTestDTO[]>([]);
	const [ftnTests, setFtnTests] = useState<FingerToNoseTestDTO[]>([]);
	const [ftnTestItems, setFtnTestItems] = useState<FingerToNoseTestItemsDTO[]>([]);
	const [ftnTestTimeline, setFtnTestTimeline] = useState<FingerToNoseTestTimelineDTO[]>([]);
	const [mrbTests, setMrbTests] = useState<ModifiedRombergTestDTO[]>([]);
	const [mrbTestItems, setMrbTestItems] = useState<ModifiedRombergTestItemDTO[]>([]);
	const [fetchDataError, setFetchDataError] = useState<any>(null);

	useEffect(() => {
			const fetchData = async () => {
				try {
					await fetchAndSetArideData(
						caseId,
						setPupilSizeTests,
						setLocTests,
						setFtnTests,
						setFtnTestItems,
						setFtnTestTimeline,
						setMrbTests,
						setMrbTestItems,
					);
				} catch (error) {
					setFetchDataError(error);
				}
			};
			fetchData();
	}, [protocolId, caseId]);

	return {
		pupilSizeTests,
		locTests,
		ftnTests,
		ftnTestItems,
		ftnTestTimeline,
		mrbTests,
		mrbTestItems,
		fetchDataError,
	};
};

export default useArideData;
