import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const pupilSizeTestAPI = {
	getPupilSizeTest: (caseId: string) => {
		return axios
			.get(`${apiUrl}/cases/${caseId}/pupil-size-test`)
			.then((response) => response.data as PupilSizeTestDTO[]);
	},
};

export default pupilSizeTestAPI;
